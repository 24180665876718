import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import Globals from '@/globals';
import PlayerApi from '@/support/playerApi';
import PlayerUtil from '@/support/playerUtil';
import Util from '@/support/utility';
import Browser from '@/support/browser';
import { ServerDataModule } from '@/store/serverData';

@Component
export default class RequestAccessComponent extends Vue {
    options = {
        noAuth: Browser.getBoolParam('noAuth', false)
    };

    get serverData() {
        return ServerDataModule.serverData;
    }

    studio: string = null;
    model = Globals.NewRequestAccess;
    errors: string[] = [];
    busy = false;
    loading = true;

    get isEmployee() {
        let email = (this.model.email || "");

        return -1 != email.indexOf('microsoft.com');
    }

    get submitDisabled() {
        return this.busy || this.loading || !this.model.workEmail || !this.model.company || !this.model.businessJustification;
    }

    created() {
        Debug.setDebugModule('RequestAccess', this);

        this.studio = Browser.getRouteParam(this.$route, 'studio', null);
        if (!this.studio) {
            PlayerUtil.redirectToError(this.$router);
        }

        this.model.studio = this.studio;
        this.model.email = this.serverData.email;
        this.model.fullName = this.serverData.fullName;
        this.model.provider = this.serverData.providerName;
        this.model.noAuth = this.options.noAuth;

        this.checkAlreadyRequested(this.studio);

        this.checkStudioAccess(this.studio);
    }

    mounted() {
        Debug.log('requestAccess mounted');

        this.load();
    }

    async load() {
        if (this.isEmployee) {
            this.model.provider = 'aad';
            if (!this.model.company)
                this.model.company = 'Microsoft';
            if (!this.model.workEmail)
                this.model.workEmail = this.model.email;
        }

        // TODO: check access and redirect to Awaiting access
        this.loading = false;
    }

    async submit(event) {
        try {
            if (!this.validateInput(event))
                return false;

            this.busy = true;

            let response = await PlayerApi.requestAccess(this.model);

            Debug.log('requestAccess requested access', response);

            PlayerUtil.redirectToView(this.$router, '/AwaitingApproval', this.model.studio);
        } catch (err) {
            let message = err.message || 'ERROR';
            Debug.error('requestAccess submit', message);
            Util.showError(message);
        } finally {
            this.busy = false;
        }
    }

    cancel(event) {
        PlayerUtil.redirectToNotAuthorized(this.$router);
    }

    isValidInput() {
        if (!this.model.workEmail)
            return false;
        else if (this.model.workEmail != Util.sanitizeEmail(this.model.workEmail))
            return false;

        if (!this.model.company)
            return false;
        else if (this.model.company != Util.sanitizeCompany(this.model.company))
            return false;

        if (!this.model.businessJustification)
            return false;
        else if (this.model.businessJustification != Util.sanitizeSentence(this.model.businessJustification))
            return false;

        return true;
    }

    validateInput(event: any) {
        if (this.loading)
            return;

        this.errors.splice(0, this.errors.length);

        if (!this.model.workEmail)
            this.errors.push('Work email required');
        else if (this.model.workEmail != Util.sanitizeEmail(this.model.workEmail))
            this.errors.push('Invalid work email');

        if (!this.model.company)
            this.errors.push('Company required');
        else if (this.model.company != Util.sanitizeCompany(this.model.company))
            this.errors.push('Invalid company');

        if (!this.model.businessJustification)
            this.errors.push('Business justification required');
        else if (this.model.businessJustification != Util.sanitizeSentence(this.model.businessJustification))
            this.errors.push('Invalid business justification');

        if (this.errors.length) {
            if (event.preventDefault)
                event.preventDefault();
            return false;
        }

        return true;
    }

    async checkAlreadyRequested(studio: string) {
        try {
            let response = await PlayerApi.requestAccessData(studio);
            if (response) {
                Debug.log('requestAccess already requested access', response);
                PlayerUtil.redirectToStudioView(this.$router, '/AwaitingApproval', studio);
            }

        } catch (err) {
            let message = err.message || 'ERROR';
            Debug.log('requestAccess- getRequestAccessData Failed: ' + message);
        }
    }

    async checkStudioAccess(studio: string) {
        try {
            let response = await PlayerApi.checkStudioAccess(studio);

            if (response.hasAccess) {
                Debug.log('checkStudioAccess user has access', studio);

                PlayerUtil.redirectToStudioView(this.$router, '/Studio', studio);
                return true;
            }

            Debug.log('checkStudioAccess - no access to', studio, response);
            return false;

        } catch (err) {
            let message = err.message || 'ERROR';
            Debug.error('checkStudioAccess error', message);
            return false;
        }
    }

}
